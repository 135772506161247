const servicesList = document.querySelectorAll(".myservices__item");
const imagesList = document.querySelectorAll(".home-services__images > img");
const serviceListPageService = document.querySelectorAll(
  ".services .myservices__item"
);

servicesList?.forEach((item) => {
  item.addEventListener("click", () => {
    item.classList.toggle("myservices__item--active");
    let index = item.getAttribute("data-index");
    let image = document.querySelector(
      `.home-services__images > img[data-index="${index}"]`
    );
    image?.setAttribute("data-hidden", "false");

    closeAll(item, image);
  });
});

function closeAll(currentService, currentImage) {
  servicesList?.forEach((service) => {
    if (service !== currentService) {
      service.classList.remove("myservices__item--active");
    }
  });
  imagesList?.forEach((image) => {
    if (image !== currentImage) {
      image.setAttribute("data-hidden", "true");
    }
  });
}

serviceListPageService?.forEach((item) => {
  let itemHeight = item.offsetHeight / 4;
  let titleHeight = item.querySelector(".accordion__toggle").offsetHeight / 2;

  if (window.matchMedia("(min-width: 992px)").matches) {
    item.querySelector(".accordion").style.transform = `translateY(${
      itemHeight - titleHeight
    }px)`;
  }
});
