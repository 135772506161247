window.addEventListener("DOMContentLoaded", () => {
  const creators = document.querySelectorAll(".creators__item");
  const images = document.querySelectorAll(".creators__images > img");

  creators.forEach((item) => {
    item.addEventListener("mouseover", () => {
      item.setAttribute("data-expanded", "true");
      const index = item.getAttribute("data-index");
      const image = document.querySelector(
        `.creators__images > img[data-index="${index}"]`
      );
      image.setAttribute("data-hidden", "false");
      removeAttributes(item, image);
    });
  });

  function removeAttributes(currentCreator, currentImage) {
    creators.forEach((creator) => {
      if (creator !== currentCreator) {
        creator.setAttribute("data-expanded", "false");
      }
    });
    images.forEach((image) => {
      if (image !== currentImage) {
        image.setAttribute("data-hidden", "true");
      }
    });
  }

  const creatorsSwiper = new Swiper("#creators-swiper", {
    slidePerView: 1,
  });
});
