const burgerToggle = document.querySelector("#burger-toggle");
const primaryNavigation = document.querySelector("#primary-navigation");
const menuImages = document.querySelectorAll("#menu-images > img");

burgerToggle.addEventListener("click", () => {
  const isOpened = burgerToggle.getAttribute("aria-expanded") === "true";
  isOpened ? closeMenu() : openMenu();
});

function closeMenu() {
  burgerToggle.setAttribute("aria-expanded", "false");
  primaryNavigation.setAttribute("data-state", "closed");
  menuImages.forEach((item) => {
    item.setAttribute("data-fadein", "false");
  });
}

function openMenu() {
  burgerToggle.setAttribute("aria-expanded", "true");
  primaryNavigation.setAttribute("data-state", "opened");
  setTimeout(() => {
    menuImages[0].setAttribute("data-fadein", "true");
  }, 200);
}

window.addEventListener("scroll", () => {
  const st = window.pageYOffset;
  if (st >= 0) {
    closeMenu();
  }
});
