const altImage = document.querySelectorAll('.swiper-slide img')

const heroSwiper = new Swiper('#hero-swiper', {
  slidePerView: 1,
  effect: 'fade',
  pagination: {
    el: '#hero-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className} text-white"></span>`
    },
  },
})
