const selectFields = document.querySelector("select.objet");
const servicesBtn = document.querySelectorAll(".myservices__btn");
const formulaire = document.querySelector("#contact-form");

Object.keys(my_options)?.forEach((value) => {
  let option = document.createElement("option");
  option.value = value;
  option.text = my_options[value];
  selectFields?.appendChild(option);
});

servicesBtn?.forEach((btn) => {
  btn.addEventListener("click", () => {
    selectFields.value = btn.getAttribute("data-service");
    formulaire.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  });
});
