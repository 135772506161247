const menuItems = document.querySelectorAll(".menu__pages a[data-hover-image]");
const figuremenu = document.querySelector("#menu-images");

menuItems.forEach((item) => {
  const imageUrl = item.getAttribute("data-hover-image");
  const image = new Image();

  imageUrl !== "" && (image.src = imageUrl);

  figuremenu.appendChild(image);

  document
    .querySelectorAll("#menu-images img")
    .forEach((item) => item.setAttribute("data-fadein", "false"));
  document
    .querySelector("#menu-images > img")
    .setAttribute("data-fadein", "true");

  item.addEventListener("mouseover", () => {
    document
      .querySelectorAll("#menu-images img")
      .forEach((item) => item.setAttribute("data-fadein", "false"));
    image.setAttribute("data-fadein", "true");
  });
});
