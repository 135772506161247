const header = document.querySelector('#header')
const onShop = header.getAttribute('data-onshop') === 'true'
const input = document.querySelector('.header__search')
const menu = document.querySelector('#menu-menu-principal')
const headerWrapper = document.querySelector('.header > .wrapper')
const galery = document.querySelector('#galery-shop')
const boutonFiltre = document.querySelector('#filter-toggle')

function responsiveHeader() {
  window.matchMedia('(max-width: 576px)').matches && logo.setAttribute('data-size', 'min')
  window.matchMedia('(min-width: 575px)').matches && logo.setAttribute('data-size', 'max')
}

function placeSearchIntoMenu() {
  window.matchMedia('(max-width: 768px)').matches && menu.appendChild(input)
  window.matchMedia('(min-width: 767px)').matches && headerWrapper.appendChild(input)
}

responsiveHeader()
placeSearchIntoMenu()

const hideHeader = () => {
  header.classList.add('header--hide')
}

const showHeader = () => {
  header.classList.remove('header--hide')
  header.classList.add('header--min')
}

let lastScrollTop = 0

window.addEventListener('DOMContentLoaded', () => {
  showHeader()
  const st = window.pageYOffset
  if (st < 400) {
    header.classList.remove('header--min')
  }
})

window.addEventListener('scroll', () => {
  const st = window.pageYOffset
  st >= 400
    ? (hideHeader(), st < lastScrollTop && header.classList.remove('header--hide'), (lastScrollTop = st <= 0 ? 0 : st))
    : showHeader()
  st <= 0 && header.classList.remove('header--min')

  onShop &&
    galery.getBoundingClientRect().top <= header.clientHeight + boutonFiltre.clientHeight &&
    galery.getBoundingClientRect().bottom > header.clientHeight + boutonFiltre.clientHeight &&
    hideHeader()
})

// if(!onShop) {
//   window.addEventListener("scroll", () => {
//     const currentScroll = window.pageYOffset;

//   if (currentScroll <= 0) {
//     header.classList.remove("header--hide");
//     header.classList.remove("header--min");
//     return;
//   }

//     if (currentScroll > lastScroll) {
//       header.classList.add("header--hide");
//       header.classList.add("header--min");
//     } else if (currentScroll < lastScroll) {
//       header.classList.remove("header--hide");
//     }

//     lastScroll = currentScroll;
//   });
// }

// if (onShop) {
//   const galery = document.querySelector('#filter-menu');
//   const boutonFiltre = document.querySelector('#filter-toggle');

//   window.addEventListener("scroll", () => {
//     const currentScroll = window.pageYOffset;

//     if (currentScroll <= 0) {
//       header.classList.remove("header--hide");
//       header.classList.remove("header--min");
//     }

//     if(galery.getBoundingClientRect().y <= header.clientHeight + boutonFiltre.clientHeight && galery.getBoundingClientRect().y > -1) {
//       header.classList.add("header--hide");
//     } else {
//       if (currentScroll > lastScroll) {
//         header.classList.add("header--hide");
//         header.classList.add("header--min");
//       } else if (currentScroll < lastScroll) {
//         header.classList.remove("header--hide");
//       }
//     }

//     lastScroll = currentScroll;
//   });
// }

window.addEventListener('resize', () => {
  responsiveHeader()
  placeSearchIntoMenu()
})
