const formInputs = document.querySelectorAll(
  ".wpcf7-form input, .wpcf7-form textarea"
);

formInputs.forEach((input) => {
  input.addEventListener("focus", () => {
    const label = input.parentNode.parentNode;
    label.classList.add("has-value");
  });

  input.addEventListener("focusout", () => {
    const label = input.parentNode.parentNode;
    input.value ? "" : label.classList.remove("has-value");
  });
});
