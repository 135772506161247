const accountFormRows = document.querySelectorAll('.woocommerce-account form .woocommerce-form-row')

accountFormRows?.forEach((row) => {
  const input = row.querySelector('input')
  const label = row.querySelector('label')

  if (!input || !label) return

  if (input.value) {
    label.classList.add('has-value')
  }

  input.addEventListener('focus', () => {
    label.classList.add('has-value')
  })

  input.addEventListener('blur', () => {
    if (input.value) {
      label.classList.add('has-value')
    } else {
      label.classList.remove('has-value')
    }
  })
})
