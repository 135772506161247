const accordions = document.querySelectorAll(".accordion");
const footerAccordions = document.querySelectorAll(".footer .accordion");

accordions?.forEach((accordion) => {
  const toggle = accordion.querySelector(".accordion__toggle");

  accordion.addEventListener("click", () => {
    toggle.setAttribute(
      "aria-expanded",
      toggle.getAttribute("aria-expanded") === "true" ? "false" : "true"
    );
    closeAllAccordions(accordion);
  });
});

function closeAllAccordions(currentAccordion) {
  accordions.forEach((accordion) => {
    if (accordion !== currentAccordion) {
      accordion
        .querySelector(".accordion__toggle")
        .setAttribute("aria-expanded", "false");
    }
  });
}

footerAccordions?.forEach((accordionFooter) => {
  const toggle = accordionFooter.querySelector(".accordion__toggle");

  accordionFooter.addEventListener("click", () => {
    window.matchMedia("(min-width: 768px)").matches &&
      toggle.setAttribute("aria-expanded", "false");
  });
});
