if (document.querySelector('main.galery')) {
    const btnItems = document.querySelectorAll(".filter");
    const filterMenu = document.querySelector("#filter-menu");
    const filterToggle = document.querySelector("#filter-toggle");
    const closeFilterMenu = document.querySelector("#close-filter");
    const boutonTermine = document.querySelector("#bouton-termine");
    const galeryWrapper = document.querySelector('.galery__wrapper');

    const catList = document.getElementById('categories-list');
    const stylesList = document.getElementById('styles-list');
    const achetableFilterList = document.getElementById('achetable-filter');
    const createurList = document.getElementById('createur-filter');

// Filtrage par URL
    const urlParams = new URLSearchParams(window.location.search);
    const paramCategorie = urlParams.get('categorie');
    const paramStyle = urlParams.get('style');
    const paramAchetable = urlParams.get('achetable');
    const paramCreateur = urlParams.get('createur');

    let grid_galery = document.querySelector(".galery .grid");
    if (grid_galery) {
        var masonryGridGalery = new Masonry(grid_galery, {
            // options
            columnWidth: '.grid-sizer',
            itemSelector: ".grid__item",
            gutter: 24,
            percentPosition: true,
            // horizontalOrder: true
        });
    }

    const openFilter = () => {
        filterToggle?.setAttribute("aria-expanded", "true");
        filterMenu?.setAttribute("data-toggle", "true");
    };

    const closeFilter = () => {
        filterToggle?.setAttribute("aria-expanded", "false");
        filterMenu?.setAttribute("data-toggle", "false");
    };

    closeFilter();
    if (window.matchMedia("(min-width: 768px)").matches) {
        openFilter(); // ouvrir le panel de filtre sur desktop par défaut.
    }
    filterToggle?.addEventListener("click", () => {
        let state = filterToggle.getAttribute("aria-expanded") === "true";
        state ? closeFilter() : openFilter();
        if (window.matchMedia("(min-width: 768px)").matches) {
            masonryGridGalery.layout(); // recalculer la grid à l'ouverture / fermeture du panel filtres sur desktop.
        }
    });

    closeFilterMenu?.addEventListener("click", () => {
        filterToggle.click();
    });

    boutonTermine?.addEventListener('click', () => {
        filterToggle.click()
    })

    btnItems?.forEach((item) => {
        item.addEventListener("click", () => {
            let state = item.getAttribute("aria-expanded") === "true";
            item.setAttribute("aria-expanded", !state);
        });
    });

    const pictureList = document.querySelectorAll('.galery__wrapper .grid__item');

    /**
     * Change l'affichage sur la page pour indiquer quel filtre est choisi.
     * @param filterList
     * @param filterToActivate
     */
    const activateFilter = (filterList, filterToActivate) => {
        for (let i = 0; i < filterList.children.length; i++) {
            const filter = filterList.children[i];
            filter.dataset.active = "false";
        }
        filterToActivate.dataset.active = "true";
    }

    /**
     * renvoi le filtre de catégorie actuel. -1 signifie aucun filtre sur les catégories.
     * @returns {any}
     */
    const getCurrentCategoryFilter = () => {
        const category = document.querySelector('#categories-list [data-active="true"]');
        return JSON.parse(category.dataset.categoryId);
    }

    /**
     * renvoi le filtre de style actuel. -1 signifie aucun filtre sur les styles.
     * @returns {any}
     */
    const getCurrentStyleFilter = () => {
        const style = document.querySelector('#styles-list [data-active="true"]');
        return JSON.parse(style.dataset.styleId);
    }

    /**
     * renvoi le filtre sur la possibilité d'achat actuel. -1 signifie aucun filtre actuel.
     * @returns {any}
     */
    const getCurrentIsAchetableFilter = () => {
        const achetableFilter = document.querySelector('#achetable-filter [data-active="true"]');
        return JSON.parse(achetableFilter.dataset.filterAchetable);
    }

    /**
     * renvoi le filtre sur les créateurs actuel. -1 signifie aucun filtre sur les créateurs.
     * @returns {any}
     */
    const getCurrentCreateurFilter = () => {
        const createur = document.querySelector('#createur-filter [data-active="true"]');
        return JSON.parse(createur.dataset.createurId);
    }

    /**
     * Cache les photos qui ne correspondent pas aux filtres en cours.
     */
    const recalculatePictures = () => {
        const currentCategory = getCurrentCategoryFilter();
        const currentStyle = getCurrentStyleFilter();
        const currentIsAchetableFilter = getCurrentIsAchetableFilter();
        const currentCreateurFilter = getCurrentCreateurFilter();
        pictureList.forEach(picture => {
            const pictureCategories = JSON.parse(picture.dataset.categories);
            const pictureStyles = JSON.parse(picture.dataset.styles);
            const pictureIsAchetable = JSON.parse(picture.dataset.achetable);
            const pictureCreateur = JSON.parse(picture.dataset.createur);

            if (currentCategory !== -1 && !pictureCategories.includes(currentCategory)) {
                picture.style.display = 'none';
            } else if (currentStyle !== -1 && !pictureStyles.includes(currentStyle)) {
                picture.style.display = 'none';
            } else if (currentIsAchetableFilter !== -1 && pictureIsAchetable !== currentIsAchetableFilter) {
                picture.style.display = 'none';
            } else if (currentCreateurFilter !== -1 && pictureCreateur !== currentCreateurFilter) {
                picture.style.display = 'none';
            } else {
                picture.style.display = 'initial';
            }
        })
        masonryGridGalery.layout(); // Nécessaire pour que masonry rerender la grid.
        window.scrollTo(0, galeryWrapper.offsetTop + 1); // reset le scroll en haut, évite de sortir de la galerie si on a scroll et que trop d'éléments disparaissent.
    }

    /**
     * Gestion du filtrage par URL
     */
    const defaultFilters = () => {
        if (paramCategorie) {
            defaultCategorieFilter = document.querySelector(`#categories-list [data-category-id="${paramCategorie}"]`);
            if (defaultCategorieFilter) {
                activateFilter(catList, defaultCategorieFilter);
            }
        }
        if (paramStyle) {
            defaultStyleFilter = document.querySelector(`#styles-list [data-style-id="${paramStyle}"]`);
            if (defaultStyleFilter) {
                activateFilter(stylesList, defaultStyleFilter);
                document.querySelector('#filter-menu > ul > li:nth-child(2) > button').ariaExpanded = "true";
            }
        }
        if (paramAchetable) {
            defaultAchetableFilter = document.querySelector(`#achetable-filter [data-filter-achetable="${paramAchetable}"]`);
            if (defaultAchetableFilter) {
                activateFilter(achetableFilterList, defaultAchetableFilter);
                document.querySelector('#filter-menu > ul > li:nth-child(3) > button').ariaExpanded = "true";
            }
        }
        if (paramCreateur) {
            defaultCreateurFilter = document.querySelector(`#createur-filter [data-createur-id="${paramCreateur}"]`);
            if (defaultCreateurFilter) {
                activateFilter(createurList, defaultCreateurFilter);
                document.querySelector('#filter-menu > ul > li:nth-child(3) > button').ariaExpanded = "true";
            }
        }
        recalculatePictures();
    }
    defaultFilters();

// ajoute ecouteur d'evenements sur les filtres Categories
    for (let i = 0; i < catList?.children.length; i++) {
        const cat = catList.children[i];
        cat?.addEventListener('click', () => {
            activateFilter(catList, cat);
            recalculatePictures();
        });

    }

// ajoute ecouteur d'evenements sur les filtres styles
    for (let i = 0; i < stylesList?.children.length; i++) {
        const style = stylesList.children[i];
        style?.addEventListener('click', () => {
            activateFilter(stylesList, style);
            recalculatePictures();
        });
    }

// ajoute ecouteur d'evenements sur les filtres achetable
    for (let i = 0; i < achetableFilterList?.children.length; i++) {
        const achetableFilter = achetableFilterList.children[i];
        achetableFilter?.addEventListener('click', () => {
            activateFilter(achetableFilterList, achetableFilter);
            recalculatePictures();
        });
    }

// ajoute ecouteur d'evenements sur les filtres createur
    for (let i = 0; i < createurList?.children.length; i++) {
        const createur = createurList.children[i];
        createur?.addEventListener('click', () => {
            activateFilter(createurList, createur);
            recalculatePictures();
        });
    }

// Bouton 'Effacer tout', efface les filtres
    const categoryDefaultFilter = document.querySelector('#categoryReset')
    const styleDefaultFilter = document.querySelector('#styleReset')
    const isAchetableDefaultFilter = document.querySelector('#isAchetableReset')
    const createurDefaultFilter = document.querySelector('#createurReset')
    document.querySelector('.galery__btn--clear')?.addEventListener('click', () => {
        activateFilter(catList, categoryDefaultFilter);
        activateFilter(stylesList, styleDefaultFilter);
        activateFilter(achetableFilterList, isAchetableDefaultFilter);
        activateFilter(createurList, createurDefaultFilter);
        recalculatePictures();
    });
}