const formComponentInputs = document.querySelectorAll('.form__input')
formComponentInputs?.forEach((input) => {
  input.addEventListener('blur', () => {
    if (input.value) {
      input.classList.add('form__input--filled')
    } else {
      input.classList.remove('form__input--filled')
    }
  })
})
