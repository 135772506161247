// Ajoute classe au nom des variations (Format...)
const productVariationsName = document.querySelectorAll(
  ".product .variations .label label"
);
productVariationsName.forEach((variation) =>
  variation.classList.add("title", "title--small")
);

// Ajoute classe aux boutons de variations (70x50cm...)
const productVariationsLabels = document.querySelectorAll(
  '.product .variations .value input[type="radio"]+label'
);
productVariationsLabels?.forEach((label) => label.classList.add("btn"));

// calcule la couleur des boutons
const couleurBoutons = () => {
  const selectedProductVariationsLabels = document.querySelectorAll(
    '.product .variations .value input[type="radio"]:checked:not(:disabled)+label'
  );
  selectedProductVariationsLabels?.forEach((label) => {
    label.classList.remove("btn--blue");
    label.classList.add("btn--bgblue");
  });

  const notSelectedProductVariationsLabels = document.querySelectorAll(
    '.product .variations .value input[type="radio"]:not(:checked):not(:disabled)+label'
  );
  notSelectedProductVariationsLabels?.forEach((label) => {
    label.classList.remove("btn--bgblue");
    label.classList.add("btn--blue");
  });
};
couleurBoutons();

window.onload = () => {
  // besoin de le reset car l'état :disabled est chargé après le chargement de la page.
  const disabledProductVariationsLabels = document.querySelectorAll(
    '.product .variations .value input[type="radio"]:disabled+label'
  );
  disabledProductVariationsLabels?.forEach((label) => {
    label.classList = "btn";
  });
};

// Change la couleur des boutons quand on séléctionne une option
const productVariationsInput = document.querySelectorAll(
  '.product .variations .value input[type="radio"]'
);
productVariationsInput?.forEach((input) => {
  input.addEventListener("click", () => {
    couleurBoutons();
  });
});

const addToCartButton = document.querySelector(
  '.product .woocommerce-variation-add-to-cart button[type="submit"]'
);
addToCartButton?.classList.add("btn", "btn--blue");

const productComplementairesSwiper = new Swiper("#product_complementaires", {
    slidesPerView: 1.5,
    spaceBetween: 20,
    centeredSlides: true,
  breakpoints: {
    768: {
      centeredSlides: false,
      slidesPerView: 4,
      spaceBetween: 20,
      slidesOffsetAfter: 80,
    }
  },

});
