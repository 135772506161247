let grid_home = document.querySelector('.fixed-banner .grid')
if (grid_home) {
  var masonryGridHome = new Masonry(grid_home, {
    // options
    columnWidth: '.grid-sizer',
    itemSelector: '.grid__item',
    gutter: 24,
    percentPosition: true,
    // horizontalOrder: true
  })
}

let grid_product = document.querySelector('.single-product .grid')
if (grid_product) {
  var masonryGridProduct = new Masonry(grid_product, {
    // options
    columnWidth: '.grid-sizer',
    itemSelector: '.grid__item',
    gutter: 24,
    percentPosition: true,
    // horizontalOrder: true
  })
}
